export const FETCH_MVPS_START = "FETCH_MVPS_START";
export const FETCH_MVPS_FAIL = "FETCH_MVPS_FAIL";
export const FETCH_MVPS_SUCCESS = "FETCH_MVPS_SUCCESS";

export const SAVE_MVPS_START = "SAVE_MVPS_START";
export const SAVE_MVPS_FAIL = "SAVE_MVPS_FAIL";
export const SAVE_MVPS_SUCCESS = "SAVE_MVPS_SUCCESS";

export const UPDATE_CURRENT_TIME = "UPDATE_CURRENT_TIME";
export const CALCULATE_TIME_TILL_SPAWN = "CALCULATE_TIME_TILL_SPAWN";
export const CALCULATE_TIME_ALL_MVPS = "CALCULATE_TIME_ALL_MVPS";
export const CHANGE_DEFAULT_TRACKER = "CHANGE_DEFAULT_TRACKER";

export const CREATE_MVPS_SUCCESS = "CREATE_MVPS_SUCCESS";
export const CREATE_MVPS_FAIL = "CREATE_MVPS_FAIL";
export const CREATE_MVPS_START = "CREATE_MVPS_START";

export const SAVE_SINGLE_MVP_START = "SAVE_SINGLE_MVP_START";
export const SAVE_SINGLE_MVP_FAIL = "SAVE_SINGLE_MVP_FAIL";
export const SAVE_SINGLE_MVP_SUCCESS = "SAVE_SINGLE_MVP_SUCCESS";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SEND_PASSWORD_RESET_START = "SEND_PASSWORD_RESET_START";
export const SEND_PASSWORD_RESET_SUCCESS = "SEND_PASSWORD_RESET_SUCCESS";
export const SEND_PASSWORD_RESET_FAIL = "SEND_PASSWORD_RESET_FAIL";

export const DELETE_TRACKER_START = "DELETE_TRACKER_START";
export const DELETE_TRACKER_SUCCESS = "DELETE_TRACKER_SUCCESS";
export const DELETE_TRACKER_FAIL = "DELETE_TRACKER_FAIL";

export const CLEAR_AUTH_MESSAGE = "CLEAR_AUTH_MESSAGE";
export const CLEAR_MVP_MESSAGE = "CLEAR_MVP_MESSAGE";

export const FETCH_USER_KEY_START = "FETCH_USER_KEY_START";
export const FETCH_USER_KEY_SUCCESS = "FETCH_USER_KEY_SUCCESS";
export const FETCH_USER_KEY_FAIL = "FETCH_USER_KEY_FAIL";

export const SAVE_NOTIFICATIONS_START = "SAVE_NOTIFICATIONS_START";
export const SAVE_NOTIFICATIONS_SUCCESS = "SAVE_NOTIFICATIONS_SUCCESS";
export const SAVE_NOTIFICATIONS_FAIL = "SAVE_NOTIFICATIONS_FAIL";

export const INITIALIZE_SETTINGS_START = "INITIALIZE_SETTINGS_START";
export const INITIALIZE_SETTINGS_SUCCESS = "INITIALIZE_SETTINGS_SUCCESS";
export const INITIALIZE_SETTINGS_FAIL = "INITIALIZE_SETTINGS_FAIL";

export const SAVE_THEME_START = "SAVE_THEME_START";
export const SAVE_THEME_SUCCESS = "SAVE_THEME_SUCCESS";
export const SAVE_THEME_FAIL = "SAVE_THEME_FAIL";

export const SAVE_LOGS_START = "SAVE_LOGS_START";
export const SAVE_LOGS_SUCCESS = "SAVE_LOGS_SUCCESS";
export const SAVE_LOGS_FAIL = "SAVE_LOGS_FAIL";

export const FETCH_ALL_TRACKERS_START = "FETCH_ALL_TRACKERS_START";
export const FETCH_ALL_TRACKERS_SUCCESS = "FETCH_ALL_TRACKERS_SUCCESS";
export const FETCH_ALL_TRACKERS_FAIL = "FETCH_ALL_TRACKERS_FAIL";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";